// circle
.sc-circle {
	border-radius: 50%;
	display: block;
	height: 8px;
	left: 0;
	position: absolute;
	top: 6px;
	width: 8px;

	// active
	&.sc-1 {
		background-color: cd-palette($cd-background, 2005);
	}

	// away
	&.sc-2 {
		background-color: cd-palette($cd-background, 2007);
	}

	// busy
	&.sc-3 {
		background-color: cd-palette($cd-background, 2006);
	}

	// offline
	&.sc-4 {
		background-color: cd-palette($cd-background, 2004);
	}
}

// modal
.sc-modal-wrapper {
	background-color: cd-palette($cd-background, 2001);
	bottom: 0;
	height: 100%;
	left: 0;
	overflow: auto;
	position: absolute;
	right: 0;
	top: 0;

	.sc-modal {
		background-color: cd-palette($cd-background, 2001);
		margin: 80px auto 0;
		max-width: 700px;
		outline: 0;
		width: 70%;

		// media
		@include cd-media(desktop, tablet) {
			margin: 60px auto 0;
			width: 75%;
		}

		@include cd-media(desktop, mini) {
			margin: 40px auto 0;
			width: 80%;
		}
	}
}

// close button - modal, popover
.sc-close-float {
	border-radius: 2px;
	cursor: pointer;
	right: 2px;
	top: 2px;

	&.sc-absolute {
		position: absolute;
	}

	&.sc-fixed {
		position: fixed;
	}

	&.sc-spacing-small {
		right: 5px;
		top: 5px;
	}

	&.sc-spacing-large {
		right: 15px;
		top: 15px;
	}

	&.bg-size-small {
		font-size: cd-fontSize(s20);
	}

	&.bg-size-large {
		font-size: cd-fontSize(se2);
	}

	&.bg-dark {
		background-color: cd-palette($cd-background, 2000, .6);
		color: cd-palette($cd-color, 1001);
	}

	&:hover {
		opacity: .9;
	}
}

// side panel list
.sc-side-panel-list-wrapper {
	margin-top: 20px;
	padding: 0 115px;

	// title
	.sc-title {
		position: relative;

		h6 {
			border-bottom: 1px solid;
			border-color: cd-palette($cd-border, 3003);
			font-weight: cd-fontWeight(700);
			padding: 5px 0;

			span {
				background-color: cd-palette($cd-background, 2000, .07);
				border-radius: 2px;
				display: inline-block;
				font-size: cd-fontSize(s13);
				height: 18px;
				margin-left: 5px;
				text-align: center;
				width: 18px;
			}
		}

		.sc-icon-wrapper {
			height: 18px;
			position: absolute;
			right: 0;
			top: 7px;
			width: 18px;

			&.sc-cursor-pointer {
				cursor: pointer;

				.sc-icon {
					&:hover {
						opacity: .9;
					}
				}
			}

			.sc-icon {
				span {
					font-size: cd-fontSize(s18);
				}
			}
		}
	}

	// list
	.sc-list {
		margin-top: 15px;
		overflow-y: auto;

		.sc-item {
			Button {
				background-color: transparent;
				border: none;
				border-radius: 0;
				box-shadow: none;
				color: cd-palette($cd-color, 1004);
				cursor: pointer;
				font-size: cd-fontSize(s15);
				height: auto;
				min-height: auto;
				outline: 0;
				padding: 6px 8px;

				&:hover {
					color: cd-palette($cd-color, 1011);
				}

				span {
					justify-content: left;
					text-transform: capitalize;
				}
			}

			&.sc-active {
				background-color: cd-palette($cd-background, 2003);

				Button {
					&:hover {
						color: cd-palette($cd-color, 1004);
					}
				}
			}
		}
	}
}
