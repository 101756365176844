.sc-messages-header {
	background-color: cd-palette($cd-background, 2001);
	height: 110px;

	.cd-row {
		height: 60px;

		.cd-col {
			.sc-title {
				padding-top: 3px;
			}

			.sc-info {
				align-items: center;
				color: cd-palette($cd-color, 1004);
				display: flex;

				.sc-icon-wrapper {
					&.sc-l1 {
						.sc-icon {
							font-size: cd-fontSize(s15);
						}
					}

					&.sc-l2 {
						.sc-icon {
							font-size: cd-fontSize(s18);
						}
					}

					&.sc-l3 {
						.sc-icon {
							font-size: cd-fontSize(s16);
						}
					}

					.sc-hover {
						&:hover {
							cursor: pointer;
							opacity: .9;
						}
					}

					.sc-active {
						color: cd-palette($cd-color, 1007);
					}

					span {
						vertical-align: middle;
					}

					.sc-value {
						font-size: cd-fontSize(s14);
						margin-left: 4px;
					}

					&:not(:last-child) {
						&::after {
							color: cd-palette($cd-color, 1011);
							content: '|';
							margin-left: 6px;
							padding-right: 6px;
							vertical-align: middle;
						}
					}
				}
			}
		}
	}

	// media
	@include cd-media(desktop, smartphone) {
		.cd-row {
			.cd-col {
				padding: 0 20px;
			}
		}
	}
}

// menu - user info
.sc-users-list-menu {
	> div {
		border-radius: 0;
		margin-left: 15px;
		top: 55px !important;

		ul {
			max-height: 300px;
			min-width: 200px;
			overflow-y: auto;
			padding: 5px 0;

			li {
				border-bottom: 1px solid cd-palette($cd-border, 3004);
				cursor: default;
				display: block;
				height: auto;
				padding: 6px 15px;

				&:hover {
					background-color: cd-palette($cd-background, 2010);
				}

				&:last-child {
					border-bottom: none;
					padding-bottom: 0;
				}

				.sc-item {
					.sc-avatar {
						border-radius: 5px;
						height: 15px;
						vertical-align: top;
						width: 15px;
					}

					.sc-content {
						display: inline-block;
						margin-left: 10px;
						width: calc(100% - 45px);

						.sc-name {
							margin-top: -2px;
						}

						.sc-email {
							font-size: cd-fontSize(s14);
							margin-top: -7px;
						}
					}
				}
			}
		}
	}
}
