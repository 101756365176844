.sc-user-panel {
	Button {
		margin-top: 1px;
		border-radius: 0;
		padding: 1px 15px;
		position: relative;
		text-align: left;
		text-transform: unset;
		width: 100%;

		span {
			color: cd-palette($cd-color, 1001);
			display: block;

			.sc-title {
				text-transform: capitalize;
				width: 80%;
				margin-top: 0px;
			}

			.sc-sub-title {
				color: cd-palette($cd-color, 1004);
				font-size: cd-fontSize(s13);
				margin-top: 1px;
				padding-left: 12px;
				position: relative;
				text-transform: capitalize;
			}

			
		}
	}
}

// menu - user info
.sc-user-panel-menu {
	> div {
		border-radius: 0;
		margin-left: 15px;
		top: 55px !important;

		ul {
			min-width: 170px;
			padding: 0;

			li {
				padding: 10px;
				position: relative;

				&:first-child {
					border-bottom: 1px solid cd-palette($cd-border, 3005);
					cursor: default;

					&:hover {
						background-color: transparent;
					}

					.sc-status {
						height: 24px;
						position: relative;

						.sc-circle {
							border: none;
							cursor: pointer;
							height: 15px;
							outline: 0;
							padding: 0;
							top: 5px;
							width: 15px;

							// loop
							@for $i from 2 through 4 {
								&.sc-#{$i} {
									margin-left: #{44.4 * ($i - 1) + px};
								}
							}

							&:hover {
								opacity: .9;

								&:not(:disabled) {
									z-index: cd-zIndex(normal);
								}
							}

							&:disabled {
								cursor: default;

								&.cd-tooltip {
									position: relative;

									&::after {
										background-color: cd-palette($cd-background, 2001);
										border-radius: 50%;
										bottom: 0;
										content: '';
										height: 11px;
										left: 2px;
										position: absolute;
										right: 0;
										top: 2px;
										width: 11px;
										z-index: cd-zIndex(initial);
									}

									span {
										display: none;
									}
								}
							}
						}
					}
				}

				.material-icons {
					margin-right: 5px;
				}
			}
		}
	}
}