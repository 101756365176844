.sc-loading-animation {
	background-color: cd-palette($cd-background, 2001);
	bottom: 0;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	z-index: cd-zIndex(loader);

	/**
	* mixins
	*/
	@mixin shrinkIcon($translate) {
		0% { transform: initial; }
		10% { transform: $translate; }
		15% { transform: initial; }
	}

	@mixin lineAnimation($animation) {
		animation: $animation 3s infinite cubic-bezier(.79, .14, .15, .86);
		mix-blend-mode: color-burn;
	}

	svg {
		animation: rotateIcon 3s cubic-bezier(.72, .42, .21, .94) infinite;
		animation-delay: -.5s;
		height: 7rem;
		transform: rotate(-15deg);
		width: 7rem;

		.st0 {
			@include lineAnimation(shrinkIcon-1);
			line {
				stroke: cd-palette($cd-color, 1005);
				transform: translateY(2px);
			}
		}

		.st1 {
			@include lineAnimation(shrinkIcon-2);
			line {
				stroke: cd-palette($cd-color, 1006);
				transform: translateX(2px);
			}
		}

		.st2 {
			@include lineAnimation(shrinkIcon-3);
			line {
				stroke: cd-palette($cd-color, 1007);
				transform: translateY(-2px);
			}
		}

		.st3 {
			@include lineAnimation(shrinkIcon-4);
			line {
				stroke: cd-palette($cd-color, 1008);
				transform: translateX(-2px);
			}
		}

		line {
			animation: expandStroke 3s infinite cubic-bezier(.55, .09, .68, .53);
			opacity: .9;
			stroke-dasharray: 0, 85;
			stroke-dashoffset: 0;
			stroke-linecap: round;
			stroke-miterlimit: 10;
			stroke-width: 18;
		}
	}

	/**
	* keyframes
	*/
	@keyframes expandStroke {
		40% {
			stroke-dasharray: 0, 85;
			stroke-dashoffset: 0;
		}
		45% {
			stroke-dasharray: 77, 85;
			stroke-dashoffset: -1px;
		}
		52% { stroke-dashoffset: -0; }
		85% { stroke-dasharray: 77, 85; }
		100% { stroke-dasharray: 0, 85; }
	}

	@keyframes rotateIcon {
		5% { transform: rotate(-15deg); }
		40% { transform: rotate(345deg); }
		100% { transform: rotate(345deg); }
	}

	@keyframes shrinkIcon-1 {
		@include shrinkIcon(translate(11px, 15px));
	}

	@keyframes shrinkIcon-2 {
		@include shrinkIcon(translate(18px, -15px));
	}

	@keyframes shrinkIcon-3 {
		@include shrinkIcon(translate(-11px, -22px));
	}

	@keyframes shrinkIcon-4 {
		@include shrinkIcon(translate(-18px, 7px));
	}
}
