// modal - add channel
.sc-dot{
	color: cd-palette($cd-background, 2005);	
}
.sc-add-channel-modal {
	background-color: cd-palette($cd-background, 2001);
	margin: 80px auto 0;
	max-width: 700px;
	outline: 0;
	width: 70%;

	// header
	.sc-header {
		h3 {
			font-weight: cd-fontWeight(700);
		}

		p {
			color: cd-palette($cd-color, 1004);
			margin-top: 10px;
		}
	}

	// form
	.sc-form {
		margin-bottom: 15px;

		// form fields
		.sc-form-fields {
			padding: 15px 0 0;

			.sc-form-field {
				margin-bottom: 15px;

				label {
					color: cd-palette($cd-color, 1003);
				}

				input {
					color: cd-palette($cd-color, 1002);
				}

				.ts-note {
					color: cd-palette($cd-color, 1004);
					font-size: cd-fontSize(s12);
					margin-top: 5px;
				}
			}

			.sc-buttons {
				text-align: right;

				.sc-button {
					border-radius: 1px;
					margin: 15px 0 0;

					&.sc-cancel {
						margin-left: 5px;
					}

					&:not(:disabled) {
						color: cd-palette($cd-color, 1001);

						&.sc-cancel {
							background-color: cd-palette($cd-background, 2006);
						}

						&.sc-create-channel {
							background-color: cd-palette($cd-background, 2005);
						}
					}
				}

				.cd-error {
					background-color: cd-palette($cd-background, 2009);
					color: cd-palette($cd-color, 1001);
					margin: 15px 0;
					padding: 5px;
					text-align: center;
				}
			}
		}
	}

	// media
	@include cd-media(desktop, mini) {
		.sc-form {
			.sc-form-fields {
				.sc-buttons {
					.sc-button {
						width: 100%;

						&.sc-cancel {
							margin: 5px 0 0;
						}
					}
				}
			}
		}
	}
}
