// Color
$cd-color: (
	1000: #000000, // ns
	1001: #ffffff,
	1002: #4d394b,
	1003: #3e313c,
	1004: #717274,
	1005: #62c2a0,
	1006: #e01958,
	1007: #ecb32c,
	1008: #89d3e0,
	1009: #e74c3c, // ns
	1010: #f8f8f8, // ns
	1011: #efefef,
	1012: #f2f5fa
);

// Background Color
$cd-background: (
	2000: #000000,
	2001: #ffffff,
	2002: #4d394b,
	2003: #3e313c,
	2004: #717274,
	2005: #62c2a0,
	2006: #e01958,
	2007: #ecb32c,
	2008: #89d3e0, // ns
	2009: #e74c3c,
	2010: #f8f8f8,
	2011: #efefef,
	2012: #f2f5fa
);

// Border
$cd-border: (
	3000: #000000,
	3001: #ffffff,
	3002: #4d394b,
	3003: #3e313c,
	3004: #f8f8f8,
	3005: #efefef
);

// Box Shadow
$cd-shadow: (
	4000: #000000,
	4001: #ffffff, // ns
	4002: #efefef,
	4003: #e01958,
	4004: #dddddd
);
