.sc-side-panel-wrapper {
	// color-panel
	.sc-color-panel-wrapper {
		background-color: cd-palette($cd-background, 2003);
		bottom: 0;
		left: 0;
		position: absolute;
		right: 0;
		text-align: center;
		top: 0;
		width: 50px;
	}

	// side-panel
	.sc-side-panel {
		background-color: cd-palette($cd-background, 2002);
		color: cd-palette($cd-color, 1001);
		height: 100vh;
		margin-left: 0px;
		padding: 0;
		width: 135px;

		&.sc-view-fixed {
			bottom: 0;
			left: 0;
			position: absolute;
			right: 0;
			top: 0;
		}

		.sc-content {
			max-height: calc(100% - 78px);
			overflow: auto;
		}
	}
}
