.sc-home {
	background-color: cd-palette($cd-background, 2001);
	box-shadow: 1px 1px 1px cd-palette($cd-shadow, 4000, .07);
	margin: 40px auto 20px;
	padding: 40px 0;

	.cd-col {
		padding: 0 50px;

		.sc-title {
			h1 {
				font-weight: cd-fontWeight(700);
				margin-top: -5px;
			}

			h6 {
				span {
					font-size: cd-fontSize(s18);
					font-weight: cd-fontWeight(700);

					&:nth-of-type(1) {
						color: cd-palette($cd-color, 1006);
						margin-right: 5px;
					}

					&:nth-of-type(2) {
						color: cd-palette($cd-color, 1007);
						margin-right: 5px;
					}

					&:nth-of-type(3) {
						color: cd-palette($cd-color, 1005);
					}
				}
			}
		}

		.sc-content {
			h5 {
				margin-top: 30px;
			}
		}

		.sc-buttons {
			margin-top: 30px;

			Button {
				border-radius: 0;
				color: cd-palette($cd-color, 1001);

				&.sc-login {
					background-color: cd-palette($cd-background, 2005);
					margin-right: 15px;
				}

				&.sc-register {
					background-color: cd-palette($cd-background, 2007);
				}

				span {
					font-weight: cd-fontWeight(700);
				}
			}
		}

		&:last-child {
			text-align: center;

			img {
				margin-top: 50px;
				max-width: 450px;
				width: 100%;
			}
		}
	}

	// media
	@include cd-media(desktop, tablet) {
		margin: 15px;

		.cd-col {
			padding: 0 35px;

			.sc-content {
				h5 {
					font-size: cd-fontSize(s18);
				}
			}
		}
	}

	@include cd-media(desktop, smartphone) {
		padding: 25px 0;

		.cd-col {
			&:first-child {
				text-align: center;
			}

			.sc-title {
				.sc-banner {
					margin: 25px 0 15px;
				}
			}
		}
	}

	@include cd-media(desktop, mini) {
		padding: 25px 0;

		.cd-col {
			padding: 0 25px;

			.sc-buttons {
				Button {
					&.sc-login {
						margin-right: 10px;
					}

					span {
						font-size: cd-fontSize(s13);
					}
				}
			}
		}
	}
}