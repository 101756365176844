.sc-message-panel {
	position: relative;
	

	.sc-messages {
		background-color: cd-palette($cd-background, 2001);
		height: calc(100vh - 170px - 75px);
		overflow-y: auto;
		padding: 15px;

		.sc-channel-info {
			background-color: cd-palette($cd-background, 2012);
			padding: 10px 15px;

			h3 {
				margin-bottom: 5px;
			}
		}

		.sc-loading-message {
			.sc-fake {
				margin-top: 25px;

				.sc-fake-la {
					background-color: cd-palette($cd-background, 2011);
				}

				.sc-fake-el-la-one {
					height: 5px;
					margin-bottom: 25px;
				}

				.sc-fake-el-la-two {
					border-radius: 4px;
					float: left;
					height: 35px;
					width: 35px;
				}

				.sc-fake-el-la-three {
					height: 20px;
					margin-bottom: 5px;
					margin-left: 45px;
				}

				.sc-fake-el-la-four {
					height: 10px;
					margin-bottom: 5px;
					margin-left: 45px;
				}
			}
		}

		.sc-start-conversation {
			border: 1px solid cd-palette($cd-border, 3005);
			margin-top: 20px;

			p {
				background-color: cd-palette($cd-background, 2010);
				color: cd-palette($cd-color, 1003);
				padding: 10px;
				text-align: center;
			}
		}
	}

	// media
	@include cd-media(desktop, smartphone) {
		.sc-messages {
			// 60px: messages header
			// 75px: form
			// 60px: header
			height: calc(100vh - 60px - 75px - 60px);
		}
	}
}
